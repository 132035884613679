import React from 'react'
import { SocialIcon } from 'react-social-icons'

// Import typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import { rhythm } from '../utils/typography'

class Links extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          marginBottom: rhythm(1.5),
        }}
      >

        <SocialIcon style={{ 'box-shadow': 'none', marginRight: rhythm(1 / 2)}} url="https://www.linkedin.com/in/matthew-biddle-73b79b11" />
        <SocialIcon style={{ 'box-shadow': 'none'}} url="https://github.com/biddlem" />

      </div>
    )
  }
}

export default Links